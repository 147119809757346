// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".single-goods__content{margin:0 auto;max-width:1200px;padding:80px 0;position:relative}.single-goods__list{display:grid;grid-gap:48px;gap:48px;margin-top:80px}.single-goods__list .item{width:560px}.single-goods__list .item .thumb{height:220px}.single-goods__list .item .goods-info{height:100px;padding:24px;grid-gap:8px;grid-template-areas:\"content originalprice button\";grid-template-columns:1fr}.single-goods__list .item .goods-info .name{font-size:24px;font-weight:400}.single-goods__list .item .goods-info .desc{font-size:16px;font-weight:400;line-height:1.5}.single-goods.is-mobile .single-goods__list{display:flex;flex-direction:column;gap:16px 0;margin-top:40px;padding:0 32px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
