<template>
  <el-dialog class="invite-code-guide" :visible.sync="visible" :width="$store.getters.isMobile ? '100%' : '420px'" :title="$t('siteBuild.inviteGuide')">
    <div class="text-center text-16">
      <div class="step">
        <div class="title gear-color">{{ $t('siteBuild.step1') }}</div>
        <div class="text-color">{{ $t('siteBuild.inviteGuideTip1') }}</div>
      </div>
      <div class="step">
        <div class="title gear-color">{{ $t('siteBuild.step2') }}</div>
        <div class="text-color">{{ $t('siteBuild.inviteGuideTip2') }}</div>
      </div>
      <div class="step">
        <div class="title gear-color">{{ $t('siteBuild.step3') }}</div>
        <div class="text-color">{{ $t('siteBuild.inviteGuideTip3') }}</div>
      </div>
      <div class="step">
        <div class="title gear-color">{{ $t('siteBuild.step4') }}</div>
        <div class="text-color">{{ $t('siteBuild.inviteGuideTip4') }}</div>
      </div>
      <div class="step">
        <div class="title gear-color">{{ $t('siteBuild.step5') }}</div>
        <div class="text-color">{{ $t('siteBuild.inviteGuideTip5') }}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui'

export default {
  components: {
    [Dialog.name]: Dialog
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    init() {
      this.visible = true
    }
  }
}

</script>

<style lang="less">
.invite-code-guide {
  .step {
    margin-bottom: 20px;
  }
}
</style>
