<template>
<div class="text-widget-edit">
  <menu-item label="PC端背景" size-tip="建议宽高比（1:1）">
    <menu-background-image :target="model.pcBg" target-key="pcBg" />
    <menu-fill class="mt-2" :target="model.pcBg" target-key="pcBg" />
  </menu-item>
  <menu-item label="移动端背景" size-tip="建议宽高比（1:3）">
    <menu-background-image :target="model.mobileBg" target-key="mobileBg" />
    <menu-fill class="mt-2" :target="model.mobileBg" target-key="mobileBg" />
  </menu-item>
  <menu-item label="按钮填充">
    <menu-background-image :target="model.buttonBackground" target-key="buttonBackground" />
    <menu-fill class="mt-2" :target="model.buttonBackground" target-key="buttonBackground" />
  </menu-item>
  <menu-item label="按钮文字颜色">
    <menu-fill class="mt-2" :target="model" color-key="buttonColor" target-key="buttonColor" />
  </menu-item>
  <menu-item label="按钮圆角">
    <menu-slider v-model="model.buttonRadius" />
  </menu-item>
  <menu-item label="文本颜色">
    <menu-fill class="mt-2" :target="model" color-key="textColor" target-key="textColor" />
  </menu-item>
  <menu-item label="档位文本颜色">
    <menu-fill class="mt-2" :target="model" color-key="gearColor" target-key="gearColor" />
  </menu-item>
  <menu-item label="描述文本颜色">
    <menu-fill class="mt-2" :target="model" color-key="descColor" target-key="descColor" />
  </menu-item>
  <menu-item label="是否显示媒体">
    <menu-switch v-model="model.mediaVisible" label="开启" />
  </menu-item>
  <div v-for="(item, index) in model.mediaList" :key="index" class="media-item bg-fillColor-2 p-2 mt-4">
    <div class="flex justify-between text-12">
      <span>媒体{{ index + 1 }}</span>
      <i class="el-icon-delete cursor-pointer hover:text-danger" @click="model.mediaList.splice(index, 1)" />
    </div>
    <menu-item class="pt-3" label="图片">
      <menu-background-image :target="item" image-key="logo" />
    </menu-item>
    <menu-item class="pt-2" label="链接">
      <base-input v-model="item.url" />
    </menu-item>
  </div>
  <menu-button class="mt-4" icon="add" @click.native.stop="handleAdd">添加媒体项</menu-button>
  <remove-btn class="mt-4" />
</div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import RemoveBtn from "~/components/common/RemoveBtn.vue";
import MenuSlider from "~/components/menu/menuSlider.vue";
import BaseInput from "~/components/base/baseInput.vue";
import MenuButton from "~/components/menu/menuButton.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";

export default {
  name: "InviteCodeEdit",
  components: {MenuSwitch, MenuButton, BaseInput, MenuSlider, RemoveBtn, MenuBackgroundImage, MenuFill, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    handleAdd() {
      this.model.mediaList.push(this.model.__getMediaItem())
    }
  }
}
</script>

<style scoped lang="less">

</style>
