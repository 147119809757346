<template>
  <client-only>
    <div class="ui-setting-menu relative" :class="{ 'active': selected, 'hide': !visible }" @click="$emit('click', $event)" @mouseenter="onmouseenter" @mouseleave="onmouseleave">
      <slot>
        <div class="flex items-center">
<!--          <im-icon class="text-18" :icon="menuIcon"></im-icon>-->
          <span class="name flex-1">
            {{ label }}
          </span>
        </div>
        <div class="handle-box flex items-center justify-center">
          <div v-if="deleteVisible || isTutorial" class="flex items-center justify-center handle-box__item" @click="handleRemove">
            <i class="el-icon-delete" />
          </div>
          <div v-if="(!global && !isDefault) || isTutorial" class="flex items-center justify-center handle-box__item" @click.stop="handleToggleVisible">
            <component :is="visible ? 'icon-show' : 'icon-hide'" class="text-20 toggle-visible" :class="{ 'block': !visible || isTutorial }" />
          </div>
          <div v-if="dragVisible || isTutorial" class="flex items-center justify-center move-btn handle-box__item">
            <icon-move class="cursor-move" />
          </div>
          <div v-if="isLeaf" class="flex items-center justify-center handle-box__item" @click.stop="handleLeafClick">
            <triangle-svg class="leaf-icon" :class="{ 'expand': expand }" />
          </div>
        </div>
      </slot>
    </div>
  </client-only>
</template>
<script>
import triangleSvg from '~/assets/svg/icon/triangle.svg'
import iconShow from '@/assets/svg/icon/iconShow.svg'
import iconHide from '@/assets/svg/icon/iconHide.svg'
import iconMove from '@/assets/svg/icon/iconMove.svg'
import ImIcon from "~/components/common/ImIcon"
export default {
  name: "WidgetTreeItem",
  components: {
    ImIcon,
    triangleSvg,
    iconShow,
    iconMove,
    iconHide
  },
  props: {
    global:{
      type: Boolean,
      default: false,
    },
    index: {
      type: String,
      default: '',
    },
    isTutorial: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default() {
        return {}
      }
    },
    isDefault: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    dragging: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    isLeaf: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    dragVisible() {
      return (this.show && this.draggable) && this.visible
    },
    deleteVisible() {
      const base = (!this.global && !this.isDefault) && this.show
      return base && this.visible
    },
    menuIcon() {
      return (this.selected ? (this.info.iconSelected || this.icon) : this.icon).toString()
    }
  },
  methods: {
    handleLeafClick() {
      this.$emit('leaf-click')
    },
    onmouseenter(event) {
      event.preventDefault()
      if (this.dragging) {
        return
      }
      this.show = true
    },
    handleToggleVisible() {
      this.$emit('visible-change')
    },
    handleRemove() {
      this.$emit('delete')
    },
    onmouseleave(event) {
      event.preventDefault()
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.ui-setting-menu {
  display: flex;
  height: 36px;
  line-height: 36px;
  padding-left: 12px;
  position: relative;
  background-color: @widget-menu-bg;
  cursor: pointer;
  width: 100%;
  transition: color linear 0.36s;
  border-radius: @border-radius-base;
  overflow: hidden;
  font-size: 12px;
  color: white;
  .toggle-visible {
    display: none;
  }

  &:hover {
    background-color: @fill-color-3;
    .toggle-visible {
      display: block;
    }
  }
  &.active{
    background-color: @primary-color-light;
    .leaf-icon {
      fill: white;
    }
    .handle-box {
      .im-icon {
        color: @text-color-secondary;
      }
    }
  }
  &.hide {
    color: @fill-color-3;
    background-color: @fill-color-2;
    .leaf-icon {
      fill: @fill-color-3;
    }
    .toggle-visible {
      color: @text-color-secondary;
    }
  }


  .leaf-icon {
    transition: all ease-in-out 0.36s;
    cursor: pointer;
    fill: @text-color-secondary;
    &.expand {
      transform: rotate(90deg);
    }
  }
  .handle-box {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    &__item {
      width: 18px;
      height: 18px;
      border-radius: @border-radius-base;
      margin-right: 6px;
      &:hover {
        background-color: @handle-bg;
      }
    }
  }
}
</style>
