
<template>
<div class="invite-code__rule relative">
  <div class="font-bold title gear-color">{{ $t('siteBuild.ruleDesc') }}：</div>
  <rich-text v-model="model.rule" class="content" :exclude="['font-size']" :editing="editing" :disabled="!editing" />
</div>
</template>


<script>
import RichText from "~/components/richText/index.vue";
export default {
  components: {
    RichText
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>


<style lang="less">
.invite-code__rule {
  .ql-editor {
    * {
      line-height: 2rem;
    }
  }
}

</style>
