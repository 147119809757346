<template>
  <component :is="isMobile ? 'el-drawer': 'el-dialog'" v-bind="componentProps" @close="onClose">
    <i class="el-icon-close goods-detail__close" :style="getTextColor(model.goodsTitleColor, model.goodsTitleColorShow)" @click="cancel" />
    <bg-style class="goods-detail__content" :bg="model.infoBackground">
      <goods-info ref="info" :good="good" :model="model" :is-mobile="isMobile" :store-lang="storeLang" @buy="handleBuy"/>
    </bg-style>
  </component>
</template>

<script>
import { Dialog, Drawer } from "element-ui";
import GoodsInfo from './oneGood/info.vue'
import {getTextColor} from "~/utils";
export default {
  name: "GoodsDetail",
  components: {
    [Dialog.name]: Dialog,
    [Drawer.name]: Drawer,
    GoodsInfo
  },
  props: {
    storeLang: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    good: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      loading: true
    }
  },
  computed: {
    componentProps() {
      const drawer = {
        direction: 'btt',
        size: 'auto',
        showClose: false,
        appendToBody: true,
        visible: this.visible,
        class: 'goods-detail'
      }
      const dialog = {
        visible: this.visible,
        class: 'goods-detail',
        width: '530px',
        appendToBody: true,
        showClose: false,
        closeOnClickModal: false
      }
      return this.isMobile ? drawer : dialog
    }
  },
  methods: {
    getTextColor,
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.info.init()
      })
    },
    cancel() {
      this.visible = false
    },
    onClose() {
      this.$emit('close')
    },
    handleBuy() {
      this.$emit('buy', this.good)
    }
  }
}
</script>

<style lang="less">
.goods-detail {
  .el-drawer {
    background: unset;
  }
  .el-dialog {
    background: unset;
  }
  .el-drawer__header {
    display: none;
  }
  .el-drawer__body {
    max-height: 95vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    .goods-detail__content {
      padding: 52px 20px 38px 20px;
    }
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  &__content {
    padding: 64px 50px 36px 50px;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }
  &__close {
    font-size: 20px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    font-weight: 600;
    z-index: 10;
  }
}
</style>
