// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activity-widget>div:last-child{max-width:1212px}.activity_content_desk{padding:86px 0}.activity_content_desk .activity_title{font-size:40px;line-height:70px}.activity_content_desk .activity_desc{font-size:24px;line-height:36px}.activity_content_mobile{padding:32px 0 32px 16px}.activity_content_mobile .activity_title{font-size:20px;line-height:36px}.activity_content_mobile .activity_desc{font-size:14px;line-height:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
