<template>
  <bg-style class="single-goods__item" :class="{ 'is-mobile': isMobile }" :style="goodsColor" @click.native="handleSubmit">
    <cover-btn v-if="editing && !goodModel.goodsId" title="设置商品" />
    <bg-style class="thumb flex justify-center items-center" :bg="goodModel.logoBackground">
      <im-image v-if="isHasCorner" :src="good.corner" class="corner-logo" />
      <div v-if="isShowCountdown" class="countdown">
        <span class="day">{{ countdownDays }} <em class="not-italic">Day</em></span>
        <span>{{ countdownHours }}:{{ countdownMinutes }}:{{ countdownSeconds }}</span>
      </div>
      <img class="max-w-full max-h-full object-contain relative" :src="good.icon" alt="">
    </bg-style>
    <div class="goods-info relative">
      <div class="content">
        <div class="name">{{ good.names?.lang_id }}</div>
        <div class="desc">{{ good.descriptions?.lang_id }}</div>
      </div>
      <div class="handle">
        <bg-style class="button" :bg="goodModel.buttonBackground" :radius="12">
          <span class="price relative">{{ realPriceText }}</span>
        </bg-style>
        <div v-if="isShowOriginalPrice" class="origin-price relative">{{ originalPriceText }}</div>
      </div>
    </div>
  </bg-style>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import tinycolor from 'tinycolor2'
import goodMixins from "~/site/widgets/storeWidget/oneGood/goodMixins";
import {getTextColor} from "~/utils";
import CoverBtn from "~/components/common/CoverBtn.vue";

export default {
  name: "GoodsItem",
  components: {CoverBtn},
  mixins: [goodMixins],
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    good: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false
    },
    goodModel: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    goodsColor() {
      return {
        '--goods-price-color': getTextColor(this.goodModel.priceBackground).color,
        '--goods-title-color': getTextColor(this.goodModel.nameBackground).color,
        '--goods-border-radius': this.model.goodsRadius + 'px',
        '--goods-background-color': this.goodModel.background?.backgroundColor,
        '--goods-button-color': this.goodModel.buttonBackground?.backgroundColor,
        '--goods-price-color-30': tinycolor(this.goodModel.priceBackground.backgroundColor).setAlpha(0.3).toRgbString()
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('item-click', cloneDeep(this.good), cloneDeep(this.goodModel))
    }
  }
}
</script>

<style lang="less">
.single-goods__item {
  width: 560px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(109,93,67,.24);
  border-radius: var(--goods-border-radius);
  margin: 0 auto;
  &:hover {
    transform: translateY(-4px) translateZ(0);
    .cover-wrap__btn {
      opacity: 1;
    }
  }
  .cover-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .thumb {
    height: 220px;
    & > div {
      border-radius: var(--goods-border-radius) var(--goods-border-radius) 0 0;
      //&:first-of-type {
      //  border-radius: var(--goods-border-radius) var(--goods-border-radius) 0 0;
      //}
    }
    .corner-logo {
      width: 90px;
      height: 90px;
      position: absolute;
      left: -4px;
      top: -4px;
    }
    .countdown {
      position: absolute;
      right: 0;
      top: 30px;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 4px 10px;
      color: white;
      border-radius: 20px 0 0 20px;
    }
  }
  .goods-info {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    height: 100px;
    background-color: var(--goods-background-color);
    border-radius: 0 0 var(--goods-border-radius) var(--goods-border-radius);
    .content {
      grid-area: content;
      color: var(--goods-title-color);
      .name {
        font-weight: 400;
        font-size: 24px;
      }
      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 250px;
      }
    }
    .handle {
      display: flex;
      align-items: center;
      flex-flow: row-reverse;
      .origin-price {
        text-decoration: line-through;
        margin-right: 8px;
      }
      .button {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        min-width: 116px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        flex-shrink: 0;
        & > div {
          &:first-of-type {
            box-shadow: inset 0 3px 0 var(--goods-price-color-30);
          }
        }
        .price {
          color: var(--goods-price-color);
        }
      }
    }
  }
  &.is-mobile {
    width: 100%;
    &:hover {
      transform: none;
    }
    .thumb {
      height: 180px;
    }
    .goods-info {
      height: auto;
      padding: 16px;
      .content {
        max-width: 50%;
        .name {
          font-size: 16px;
        }
        .desc {
          font-size: 14px;
        }
      }
      .handle {
        flex-direction: column;
        .origin-price {
          font-size: 12px;
          margin-top: 4px;
        }
        .button {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
