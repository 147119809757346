<template>
  <el-select ref="select" v-model="data" class="im-select" v-bind="selectProps" popper-class="im-select-popper" :clearable="clearable" @change="onChange">
    <el-option v-for="item in options" :key="item.value" :value="item[valueKey]" :label="item[labelKey]" :disabled="item.disabled">
      <div v-if="type === 'goods'" class="flex items-center relative px-5">
        <im-icon v-if="data === item[valueKey]" class="mr-3 absolute -left-1 top-2.5" icon="icon-selected" />
        <img class="w-[24px] h-[24px]" :src="item.icon" alt="">
        <span class="ml-2">{{ item.label }}</span>
      </div>
      <div v-else class="relative px-5">
        <im-icon v-if="data === item[valueKey]" class="mr-3 absolute -left-1 top-2.5" icon="icon-selected" />
        <span>{{ item[labelKey] }}</span>
      </div>
    </el-option>
    <slot name="append" />
  </el-select>
</template>

<script>
import { Select } from 'element-ui'
import { insertStep } from "~/utils/event";
export default {
  props: {
    ...Select.props,
    value: [String, Number],
    clearable: {
      type: Boolean,
      default: false
    },
    targetKey: {
      type: String,
      default: ''
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      data: this.value
    }
  },
  computed: {
    selectProps() {
      const nonNative = ['options']
      const props = {}
      for (const key in this.$props) {
        if (!nonNative.includes(key)) {
          props[key] = this.$props[key]
        }
      }
      return props
    }
  },
  watch: {
    value(n) {
      this.data = n
    }
  },
  mounted() {
    this.data = this.value
  },
  methods: {
    emitValue(value) {
      this.$emit('input', value)
    },
    onChange(value) {
      this.targetKey && insertStep(this, [this.targetKey], this.value)
      this.emitValue(value)
      this.$emit('change', value)
    },
    blur() {
      this.$refs.select.blur()
    }
  }
}
</script>

<style lang="less">
.im-select-popper {
  background-color: @select-bg;
  border-color: @select-bg;
  padding: 0 6px;
  border-radius: 2px;
  div.popper__arrow {
    display: none;
  }
  .el-select-dropdown__item {
    color: white;
    height: @base-height;
    line-height: @base-height;
    font-size: 12px;
    border-radius: 2px;
  }
  .el-select-dropdown__item.selected,.el-select-dropdown__item.selected.hover {
    //background-color: @primary-color-alpha-6 !important;
    color: white;
    font-weight: normal;
  }
  .el-select-dropdown__item.hover {
    background-color: @primary-color !important;
    color: white;
  }
  .el-select-dropdown__item:hover {
    background-color: @primary-color;
    color: white;
  }
  .el-select-dropdown__item.is-disabled:hover {
    background-color: transparent;
  }
}
.im-select {
  &.el-select .el-input__inner:focus {
    border-color: @primary-color;
  }
  div.el-input.is-focus .el-input__inner {
    border-color: @primary-color;
  }
  .el-input__inner {
    background-color: @fill-color-2;
    border-radius: @border-radius-base;
    color: white;
    font-size: 12px;
    font-weight: normal;
    border-color: transparent;
    //height: @base-height;
    //line-height: @base-height;
  }
}
</style>
